<template>
  <div class="md-news-pages-component">
    <div class="md-news-panel">
      <h3 class="panel-title">
        <p> <span class="line"></span> <span>教师信息公示</span> <span class="line"></span></p>
      </h3>
      <ul class="news-list" v-if="teacherList.length">
        <li class="news-item" v-for="(item, index) in teacherList" :key="index">
          <div class="t-img" v-if="item.avatar">
            <div class="t-img-content">
              <div>
                <img :src="`/video-dev${item.avatar}`" alt="">
              </div>

              <div class="content-right">
                <p>{{ item.name }}</p>
                <p> {{ item.tag }}</p>
              </div>
            </div>

          </div>
          <div class="t-right">
            <div class="t-tit-box">
              <div class="descList">
                <p class="desc" v-for="(list, index ) in item.description.split('\n') " :key="index">{{ list }}</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <p v-else class="no-data">暂无数据</p>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination @current-change="handleCurrentChange" :current-page="+params.pageNum" :page-size="params.pageSize"
          layout="total, prev, pager, next, jumper" :total="total" background>
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getTeacher } from '@/axios/teachers'
export default {
  name: 'mdNewsPagesComponent',
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 10
      },
      teacherList: [],
      dictList: [],
      total: 0
    }
  },
  created() {
    this.getTeacher()

  },
  methods: {
    async getTeacher() {
      const res = await getTeacher(this.params)
      if (res.code === 200) {
        this.teacherList = res.rows
        this.total = res.total
      }
    },
    handleTurnDetail(item) {
      this.$router.push({
        path: '/newsDetail',
        query: {
          rid: item.rid
        }
      })
    },
    handleCurrentChange(val) {
      this.params.pageNum = val
      this.getTeacher()
    }
  }
}
</script>
<style lang="less" scoped>
.md-news-pages-component {
  width: 100%;
  background-color: @md-bgc-primary-color;

  .md-news-panel {
    width: 1152px;
    margin: 0 auto;
    // padding: 30px 0;
  }

  .news-list {
    width: 100%;

    .news-item {
      background-color: #fff;
      display: flex;
      align-items: center;
      margin-top: 20px;
      border-radius: 12px;

      &:hover {
        .t-tit {
          color: @md-primary-color;
        }
      }

      &:first-child {
        padding-top: 0;
      }

      .t-img {
        width: 340px;
        height: 200px;
        border-radius: 12px;
        background-color:@md-primary-color;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .t-img-content {
          display: flex;
          align-items: center;

          >:nth-child(1) {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background-color: #fff;
            border: 3px solid #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .content-right {
          margin-left: 20px;
          color: #fff;

          >:nth-child(1) {
            font-size: 20px;
            font-weight: 500;
          }

          >:nth-child(2) {
            margin-top: 12px;
            font-size: 12px;
            width: 146px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
          }
        }

        img {
          width: 109px;
          height: 109px;
          border-radius: 100px;
        }
      }

      .t-right {
        flex: 1;
        // height: 160px;
        display: flex;

        // align-items: center;
        .t-tit-box {
          flex: 1;

          .t-tit {
            font-size: 16px;
            margin-bottom: 5px;
          }

          .t-type {
            span {
              color: #888;
              font-size: 12px;
              margin-right: 30px;
            }
          }
        }

        .q-btn {
          margin-left: 10px;
          flex-shrink: 0;
        }
      }
    }
  }

  .pagination {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
}

.descList {
  // margin-top: 10px;
  margin-left: 41px;
}

.desc {
  font-size: 14px;
  color: #808080;
  margin-top: 8px;
  font-size: 14px;
}

.line {
  display: inline-block;
  width: 40px;
  background-color:@md-primary-color;
  height: 3px;
}

.no-data {
  color: #999;
  font-size: 12px;
  padding: 30px 0;
  text-align: center;
}

.panel-title {
  font-size: 36px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 30px;

  p {
    display: flex;
    margin: 0 auto;
    width: 312px;
    color: @md-primary-color;
    align-items: center;

    >:nth-child(2) {
      margin-left: 8px;
      margin-right: 8px;

    }
  }
}

/deep/ .el-pagination .btn-next,
.el-pagination .btn-prev {
  // background: transparent !important;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  background-color: #fff;
}

::v-deep{
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: @md-primary-color;
  }

  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    border: 1px solid  #CCCCCC;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
    background-color: #fff;
   
  }
  .el-pagination button, .el-pagination span:not([class*=suffix]){
   height: 36px;
   line-height: 36px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
    color: @md-primary-color;
  }
}

/deep/ .el-pager li.active {
  color: @md-primary-color;
  ;
}

/deep/ .el-pager li:hover {
  color: @md-primary-color;
  ;
}

/deep/ .el-input__inner:focus {
  border-color: @md-primary-color !important;
}

/deep/ .el-pagination button:hover {
  color: @md-primary-color;
}
</style>
